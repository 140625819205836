import React from "react"
import { Button } from "@material-ui/core"
import { FooterWrapper } from "./style"
import { navigate } from "gatsby"
import PDF from "../../pdf/FTWInvokePrivacyPolicyV8.pdf"
const Footer = () => (
  <FooterWrapper>
    <div className="container">
      <div className="flex">
        <div className="left">
          <div className="flex">
            <p>
              {new Date().getFullYear()} INVOKE <sup>&trade;</sup>
            </p>

            <p>
              <a href={PDF} target="_blank " rel="noreferrer">
                Privacy
              </a>
              <a
                href="https://freethework.com/terms-conditions"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>
            </p>
          </div>
        </div>

        <div className="right">
          <Button variant="contained" onClick={() => navigate("/faq?dashboard=true")}>
            FAQs
          </Button>
        </div>
      </div>
    </div>
  </FooterWrapper>
)
export default Footer
