import React, { useState } from "react"
import Header from "./ReportDashboard/Header"
import Footer from "./ReportDashboard/Footer"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import {
  ReportDashboardWrapper,
  HelpCenterWrapper,
} from "./ReportDashboard/style"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core"
import HELPITEM from "../constant/helpItem"

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 700,
  },
}))

const HelpCenter = () => {
  const classes = useStyles()
  const [currentOpen, setCurrentOpen] = useState("")
  return (
    <ReportDashboardWrapper>
      <Header />
      <HelpCenterWrapper className="container faq">
        <div className="return">
          <Link to="/report-dashboard">
            <ArrowBackIosIcon />
            Return to dashboard
          </Link>
        </div>
        <div className="top">
          <h1>INVOKE Help Center</h1>
          <h3>How To's</h3>
        </div>

        <div className="answer">
          {HELPITEM.map((item, index) => (
            <Accordion
              key={index}
              onClick={() => setCurrentOpen(item.id)}
              expanded={item.id === currentOpen}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${index}-content`}
                id={index}
              >
                <Typography className={classes.heading}>
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div dangerouslySetInnerHTML={{ __html: item.description }} />
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </HelpCenterWrapper>
      <Footer />
    </ReportDashboardWrapper>
  )
}

export default HelpCenter
