import IMG1 from "../images/help-center/howToAccessReport.png"
import IMG2 from "../images/help-center/reportType.png"
import IMG3 from "../images/help-center/howToTag.gif"
import IMG4 from "../images/help-center/aggregated.gif"
import IMG5 from "../images/help-center/customReport.png"
import IMG6 from "../images/help-center/exportReport.png"
const HELPITEM = [
  {
    id: 1,
    title: "How do I access my reports?",
    description: `<p>All of the reports that you have access to will be available from your invokeinsights.org dashboard.  To open a report, click on the “View report” button to the right of the report.</p>
    <p><img src=${IMG1} alt="how to access my report" /></p>
    <p>NOTE: If a report is listed as “Pending,” that means it is not yet available.</p>`,
  },
  {
    id: 2,
    title: "What do the different report types mean?",
    description: `<p>There are 4 different types of report that you might see listed on your dashboard.  The report type is listed in the third column of your dashboard, and you are able to filter the dashboard view by report type.</p>
    <p><img src=${IMG2} alt="report type" /></p>
    <ul>
        <li>Proactive: These projects involve surveying crew on active productions. This can be done either on-set with INVOKE reps, or remotely.  This is INVOKE's most popular use case.</li>
        <li>Retroactive: These projects involve surveying crew on past productions. This might be done when a company is looking to collect historical data.</li>
        <li>Bidding: This project type is used on productions that are actively bidding. Surveys are distributed to directors, editors, photographers, or producers during the bidding process. This is done prior to award.</li>
        <li>Pre-enrollment: This project type is used to survey crew independently of a specific production. A common use case may be to pre-enroll all of the directors on a company's roster or to generate a demographic report on that company's roster.</li>
    </ul>`,
  },
  {
    id: 3,
    title: "How do I use tags?",
    description: `<p>Tags can be applied to reports in order to organize them by things like department, quarter, etc.  These tags can then be used to generate aggregated reports based on the tags that you apply (see “How do I see data aggregated” below).</p>
    <p>Tags can be applied from the dashboard using the 3-dot menu, or from the report view itself.</p>
    <p><img src=${IMG3} alt="how do i use tags" /></p>`,
  },
  {
    id: 4,
    title: "How do I see data aggregated across multiple reports?",
    description: `<p>If your company is also a member of FREE THE WORK, you will be able to generate aggregated reports across multiple projects.</p>
    <p>(NOTE: If you are not yet a FTW member and would like access to aggregated reports, reach out to <a href="mailto:parternships@freethework.com">parternships@freethework.com</a>.)</p>
    <p>Aggregated reports can be generated using project type, tags, or a combination of the two.  To start an aggregated report, select “Create custom report” from your dashboard.</p>
    <p><img src=${IMG4} alt="how to see multiple reports" /></p>
    <p>A prompt will open with options to select report types and tags.  You can select all report types or individual types.  Tags are also multi-select and follow “and” logic, meaning the reports must have all tags in order to be included in the aggregated report.</p>
    <p><img src=${IMG5} alt="how to see multiple reports" /></p>
    <p>Once you have entered criteria, hit “generate report” to view your custom aggregated report.  Remember to export in order to save.</p>`,
  },
  {
    id: 5,
    title: "How do I save or export a report?",
    description: `<p>In order to save a custom report, you must export it.  Use the buttons in the top right corner of the report to export your results as either a CSV or a PDF.</p>
    <p><img src=${IMG6} alt="how do i export report" /></p>`,
  },
]

export default HELPITEM
