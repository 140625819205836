import React, { useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HelpCenter from "../components/HelpCenter"
import { navigate } from "gatsby"

const HelpCenterPage = () => {
  const validToken =
    typeof window !== "undefined" &&
    localStorage.getItem("insightToken") !== null &&
    localStorage.getItem("insightToken") !== "undefined"
  useEffect(() => {
    if (!validToken) navigate("/no-access")
  }, [])

  return (
    <Layout login>
      <Seo title="Help Center - INVOKE by FREE THE WORK" />
      {validToken && <HelpCenter />}
    </Layout>
  )
}

export default HelpCenterPage
